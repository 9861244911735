import { mapGetters, mapMutations } from "vuex";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

export default {
  mixins: [waitRequest, validationError],
  data() {
    return {
      nextMediaPage: 1,
      userMediaId: null,
    };
  },
  mounted() {
    // this.initialLoadMedia();
  },

  computed: {
    ...mapGetters({
      mediaPost: "mediaPost/mediaPost",
      mediaPostMeta: "mediaPost/mediaPostMeta",
    }),

    hasMoreMedia() {
      return Boolean(this.mediaPostMeta?.next_page_url);
    },

    postsMedia() {
      return this.mediaPost || [];
    },

    pageMedia() {
      return this.mediaPostMeta?.current_page || 1;
    },
  },

  methods: {
    ...mapMutations({
      setMedia: "mediaPost/setMedia",
      updateMedia: "mediaPost/updateMedia",
      setMetaMedia: "mediaPost/setMetaMedia",
    }),

    setUserMediaId(id) {
      this.userMediaId = id;
    },

    loadMedia(successCallback) {
      const url = new URL(
        `/media/user/${this.userMediaId}`,
        process.env.VUE_APP_API_URL
      );

      url.searchParams.set("page", this.nextMediaPage);

      const requestUrl = url.pathname + url.search;

      return this.$get(
        requestUrl,
        successCallback,
        this.loadPostError,
        this.checkErrors
      );
    },

    async initialLoadMedia() {
      this.$showSpinner();

      this.loadMedia(this.setCurrentMedia);
    },

    loadMoreMedia() {
      this.nextMediaPage = this.pageMedia + 1;
      if (this.hasMoreMedia) return this.loadMedia(this.updateCurrentMedia);
    },

    setCurrentMedia(data) {
      const { current_page, data: posts } = data;

      this.setMedia({ posts });
      this.setMetaMedia({ meta: data });

      this.initialPage = current_page;
    },

    updateCurrentMedia(data) {
      const posts = data.data;

      this.updateMedia({ posts });
      this.setMetaMedia({ meta: data });
    },

    loadPostError(errors) {
      console.log(errors);
    },
  },
};
