<template>
  <span class="profile-stats">
    <span class="profile-stats__stats" v-if="stats">
      <span class="profile-stats__font profile-stats__font--stats">
        {{ stats }}
      </span>
    </span>

    <span class="profile-stats__text" v-if="text">
      <span class="profile-stats__font profile-stats__font--text">
        {{ text }}
      </span>
    </span>
  </span>
</template>

<script>
export default {
  name: "UiProfileStats",
  props: {
    text: {
      type: String,
      default: null,
    },

    stats: {
      type: [String, Number],
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-stats {
  display: inline-block;
  padding: em(4) em(7);
  border-radius: em(4);
  background: $app-gray-2;

  &__stats {
  }

  &__text {
  }

  &__font {
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    line-height: 1;

    &--counter {
      color: $app-black;
      font-weight: 500;
    }

    &--text {
      color: $app-font-color-gray-1;
      font-weight: 400;
    }
  }
}
</style>
