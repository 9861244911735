<template>
  <b-row class="profile" v-if="user">
    <b-col>
      <b-row
        class="page-header sticky-top"
        :class="[isScrolled ? ' scrolled bg-white border-bottom ' : '']"
      >
        <div class="profile-menu">
          <button
            type="button"
            class="profile-menu__button"
            @click="goBack"
          >
            <CIcon
              name="arrow-to-left"
              class="profile-menu__icon profile-menu__icon--arrow"
            />
          </button>

          <tippy
            interactive
            placement="top-center"
            distant="7"
            trigger="mouseenter"
          >
            <template #trigger>
              <CIcon
                name="dots-vertical"
                class="profile-menu__icon profile-menu__icon--dots"
              />
            </template>

            <div class="profile-menu__tooltip">
              <button
                type="button"
                @click.prevent="copyLink"
                class="profile-menu__button"
              >
                <span class="profile-menu__font profile-menu__font--tooltip">
                  {{ $t("general.copy-link-to-profile") }}
                </span>
              </button>

              <button
                type="button"
                @click="addToList(user)"
                class="profile-menu__button"
              >
                <span class="profile-menu__font profile-menu__font--tooltip">
                  {{ $t("general.add-to-list") }}
                </span>
              </button>
            </div>
          </tippy>
        </div>
      </b-row>

      <b-row>
        <div class="bg-light cover w-100 position-relative">
          <img :src="user.cover || '/mock/header.png'" alt="" />
        </div>

        <div class="subprofile w-100 d-flex">
          <div class="profile-avatar">
            <b-avatar
              :src="user.avatar || '/mock/PFP.png'"
              :text="user.initials"
              :to="user.url"
              size="80px"
            />
          </div>

          <div></div>

          <div
            class="d-flex mx-2 mt-2 ml-auto profile-navigation align-items-center"
            style="font-size: 150%"
          >
            <button
              type="button"
              v-if="!isOwner"
              @click.stop="addToList"
              class="mx-2 profile-navigation__button"
            >
              <CIcon
                name="to-collection"
                path="profile"
                class="profile-navigation__icon"
                :class="{
                  'profile-navigation__icon--active': isContains,
                }"
              />
            </button>

            <button
              type="button"
              v-if="!isOwner"
              @click.prevent="$router.push('/messages/' + user.id)"
              class="mx-2 profile-navigation__button"
            >
              <CIcon
                name="message"
                path="profile"
                class="profile-navigation__icon"
              />
            </button>

            <button
              type="button"
              v-if="!isOwner"
              @click.prevent="following"
              class="mx-2 profile-navigation__button profile-button"
            >
              <span class="profile-button__font" v-if="!isFollowed">
                {{ $t("general.profile.button.follow") }}
              </span>
              <span class="profile-button__font" v-else>
                {{ $t("general.profile.button.unfollow") }}
              </span>
            </button>

            <button
              type="button"
              v-if="isOwner"
              @click.prevent="share"
              class="mx-2 profile-navigation__button"
            >
              <CIcon
                name="share"
                path="profile"
                class="profile-navigation__icon"
              />
            </button>

            <button
              type="button"
              v-if="isOwner"
              @click.prevent="$router.push('/settings/my-profile')"
              class="mx-2 profile-navigation__button profile-button profile-button--edit-profile"
            >
              <span class="profile-button__font">
                {{ $t("general.profile.button.editProfile") }}
              </span>
            </button>
          </div>
        </div>
      </b-row>

      <b-row class="px-3 mt-2 d-block border-bottom pb-3 overflow-hidden">
        <ui-username :user="user" :asLink="false" />
        <div class="text-muted small username">@{{ user.username }}</div>

        <div class="mt-3">
          <UiProfileStats
            :stats="userStat.followers"
            :text="$t('general.stat.followers')"
          />
          <UiProfileStats
            :stats="userStat.views"
            :text="$tc('general.stat.views', userStat.views)"
          />
          <UiProfileStats
            :stats="userStat.likes"
            :text="$tc('general.stat.likes', userStat.likes)"
          />
        </div>

        <div class="mt-2" v-if="user.website">
          <a target="_blank" :href="user.website">
            {{ user.website }}
          </a>
        </div>
        <div class="mt-1">
          <span class="bio" v-if="bio.shortDescription">
            {{ bio.shortDescription }}
          </span>

          <b-collapse
            id="collapse-show-more"
            v-if="bio.showMoreDescription"
            v-model="visibleMore"
          >
            {{ bio.showMoreDescription }}
          </b-collapse>

          <button
            type="button"
            v-b-toggle:collapse-show-more
            class="show-more mt-1"
            v-if="bio.showMoreDescription"
          >
            <span>{{ !visibleMore ? "..." : "" }}</span>

            <span class="show-more__font">
              {{ moreButtonText }}
            </span>
          </button>

          <div
            class="profile-location mt-1"
            v-if="user.location && user.location.address"
          >
            <CIcon
              name="location"
              path="profile"
              class="profile-location__icon"
            />
            <span class="profile-location__font">
              {{ user.location.address }}
            </span>
          </div>
        </div>

        <PromotionCard :card="promoCard" v-if="showPromoCard" />

        <CButton
          @click="manageSubscription"
          class="mt-3"
          align="center"
          v-if="isOwner"
        >
          <span class="profile-button__font profile-button__font--white">
            {{ $t("general.profile.button.manageSubscription") }}
          </span>
        </CButton>
      </b-row>

      <!--      <b-row-->
      <!--        class="px-3 mt-3 d-block border-bottom pb-3"-->
      <!--        v-if="!user.isSubscribed && !isOwner"-->
      <!--      >-->
      <!--        <h5 class="mb-3">{{ $t("general.subscription") }}</h5>-->
      <!--        <CButton variant="primary" block @click.prevent="subscribe">-->
      <!--          {{-->
      <!--            $t("general.subscribe-for-x", [-->
      <!--              user.isFree ? $t("general.free") : user.priceFormatted,-->
      <!--            ])-->
      <!--          }}-->
      <!--        </CButton>-->
      <!--      </b-row>-->

      <!--      <b-row-->
      <!--        class="px-3 mt-3 d-block border-bottom pb-3"-->
      <!--        v-if="-->
      <!--          !isOwner &&-->
      <!--          !user.isSubscribed &&-->
      <!--          !user.isFree &&-->
      <!--          user.bundles.length > 0-->
      <!--        "-->
      <!--      >-->
      <!--        <h5 class="mb-3">{{ $t("general.subscription-bundles") }}</h5>-->
      <!--        <CButton-->
      <!--          variant="primary"-->
      <!--          block-->
      <!--          @click.prevent="subscribe(item)"-->
      <!--          v-for="(item, index) in user.bundles"-->
      <!--          :key="index"-->
      <!--        >-->
      <!--          {{ $t("general.get-x-months-for-y-z-off", item.title(user)) }}-->
      <!--        </CButton>-->
      <!--      </b-row>-->

      <CButton
        @click="userSubscribe"
        class="mt-3"
        align="space"
        v-if="isSubscribeButton"
      >
        <span class="profile-button__font profile-button__font--white">
          {{ $t("general.profile.button.subscribe") }}</span
        >
        <span class="profile-button__font profile-button__font--white">
          {{ amount }} {{ $t("general.profile.button.perMonth") }}</span
        >
      </CButton>

      <CButton
        v-if="isUserFailedCard"
        @click="userSubscribe"
        class="mt-3"
        align="space"
        theme="danger"
      >
        <span class="profile-button__font profile-button__font--white">
          {{ $t("general.profile.button.subscriptionFailed") }}</span
        >
        <span class="profile-button__font profile-button__font--white">
          {{ $t("general.profile.button.updateCard") }}</span
        >
      </CButton>

      <button
        @click="unsubscribe"
        class="subscribed-button mt-3"
        v-if="isUnsubscribeButton"
      >
        <span class="subscribed-button__font">
          {{ $t("general.my-subscription.subscribed") }}</span
        >
        <span class="subscribed-button__font">
          {{ $t("general.my-subscription.for") }} {{ amount }}</span
        >
      </button>

      <b-row v-if="showBundles">
        <b-col>
          <div class="mt-1">
            <span class="profile-discount mt-3">
              <CIcon
                name="discount"
                path="profile"
                class="profile-discount__icon profile-discount__icon--discount"
              />

              <span class="profile-discount__font"> Discount Bundles </span>

              <button
                type="button"
                v-b-toggle:collapse-discounts
                class="profile-discount__button profile-discount__button--arrow"
                :class="{
                  'profile-discount__button--arrow--open': visibleDiscount,
                }"
              >
                <CIcon
                  name="arrow-to-top"
                  path="profile"
                  class="profile-discount__icon profile-discount__icon--arrow-to-top"
                />
              </button>
            </span>

            <b-collapse id="collapse-discounts" v-model="visibleDiscount">
              <div class="profile-discount__buttons">
                <BundleButton
                  class="profile-discount__button"
                  :key="bundle.id"
                  v-for="bundle in bundles"
                  :bundle-data="bundle"
                />
              </div>
            </b-collapse>
          </div>
        </b-col>
      </b-row>

      <PostList
        :key="this.user.isSubscribed"
        :user-id="this.user.id"
        :stat="userStat"
      />
    </b-col>
  </b-row>
</template>

<script>
import Post from "@/components/models/Post";
import User from "@/components/models/User";
import Payment from "@/components/models/Payment";

import UiUsername from "@/features/ui/common/UiUsername.vue";
import CIcon from "@/features/ui/CIcon.vue";
import UiProfileStats from "@/features/ui/common/ProfileStats.vue";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import follow from "@/mixins/follow";
import PostList from "@/features/containers/profile/PostList.vue";
import { mapGetters, mapMutations } from "vuex";
import CButton from "@/features/ui/СButton.vue";
import userPosts from "@/mixins/userPosts";
import PromotionCard from "@/features/components/PromotionCard.vue";
import BundleButton from "@/features/components/BundleButton.vue";
import anonymousHandler from "@/mixins/anonymousHandler";

const promoTypes = {
  discount: 2,
  trial: 1,
};

export default {
  components: {
    BundleButton,
    PromotionCard,
    CButton,
    PostList,
    UiProfileStats,
    CIcon,
    UiUsername,
  },
  mixins: [waitRequest, validationError, follow, userPosts, anonymousHandler],
  data: function () {
    return {
      user: null,
      posts: [],
      scrollPosition: null,
      page: 1,
      hasMore: false,
      isLoading: false,
      postsType: Post.TYPE_ACTIVE,
      type: "flows",
      visibleMore: false,
      visibleDiscount: true,
    };
  },
  computed: {
    ...mapGetters({
      isContains: "selectUserCollection/isContains",
    }),
    bundles() {
      return (
        this.user?.discountBundles.map((item) => {
          return {
            ...item,
            action: () => this.activateBundle(item),
          };
        }) || []
      );
    },
    userStat() {
      return (
        this.user?.stat || {
          followers: 0,
          views: 0,
          likes: 0,
        }
      );
    },

    showPromoCard() {
      return (
        this.user.promo &&
        this.user.promo?.is_active &&
        !this.isSubscribed &&
        !this.isOwner
      );
    },

    showBundles() {
      return (
        this.user.discountBundles &&
        this.user.discountBundles.length &&
        !this.isSubscribed &&
        !this.isOwner
      );
    },

    promoCard() {
      return {
        promo: this.user.promo,
        avatar: this.user.avatar,
      };
    },

    amount() {
      if (this.user.isSubscribed) {
        return `$${this.user?.subscription?.face_amount || 0}`;
      }

      const promo = this.user?.promo;
      if (promo && promo?.is_active) {
        if (promo?.type === promoTypes.discount) {
          return promo?.face_discount_price;
        } else {
          return this.user?.face_price;
        }
      }

      return `$${this.user?.face_price || 0}`;
    },

    isUserFailedCard() {
      return Boolean(this.user.subscription?.info?.is_failed);
    },

    isSubscribeButton() {
      return !this.isOwner && this.user.face_price && !this.isSubscribed && !this.isUserFailedCard;
    },

    isUnsubscribeButton() {
      return !this.isOwner && this.user.face_price && this.isSubscribed && !this.isUserFailedCard;
    },

    isSubscribed() {
      return this.user.isSubscribed;
    },

    isScrolled: function () {
      return this.scrollPosition >= 180;
    },
    isOwner: function () {
      return this.user.id == this.$store.state.currentUser.id;
    },
    username: function () {
      return this.$route.params.username;
    },
    types: function () {
      return {
        active: Post.TYPE_ACTIVE,
        scheduled: Post.TYPE_SCHEDULED,
        expired: Post.TYPE_EXPIRED,
      };
    },

    bio() {
      const shortDescription = this.user?.bio?.slice(0, 300);
      const showMoreDescription = this.user?.bio?.slice(300, -1);

      return { shortDescription, showMoreDescription };
    },

    moreButtonText() {
      return !this.visibleMore
        ? this.$t("general.profile.button.showMore")
        : this.$t("general.profile.button.less");
    },
  },
  watch: {
    username: function (oldV, newV) {
      if (oldV && newV && oldV != newV) {
        this.reset();
        this.loadUser();
      }
    },
  },
  mounted() {
    this.initState();
  },
  methods: {
    ...mapMutations({
      setContains: "selectUserCollection/setContains",
      setCurrentSubscription: "subscriptions/setCurrentSubscription",
    }),

    initState() {
      this.loadUser().then(() => {
        if(!this.isAnonymousUser) {
          this.loadCollections();
        }
      });

      window.addEventListener("scroll", this.updateScroll);
    },

    following() {
      if (this.isAnonymousUser) {
        this.anonymousHandler();
        return;
      }
      this.follow(() => {
        this.loadUserCallback()
      })
    },
    goBack() {
      if (this.isAnonymousUser) {
        this.anonymousHandler();
        return;
      }
      this.$router.go(-1)
    },

    activateBundle(bundle) {
      if (this.isAnonymousUser) {
        this.anonymousHandler();
        return;
      }

      this.setCurrentSubscription({
        ...this.user,
        sub: bundle,
        action: () => {
          this.loadUser();
        },
        _activationType: "bundle",
      });

      this.$popup.open("SubscriptionPopup");
    },

    share() {
      const link = location.href;
      navigator.clipboard.writeText(link);
      this.$bvToast.toast(this.$t("general.copy"), {
        autoHideDelay: 2000,
        title: this.$t("general.success"),
        solid: true,
        toaster: "b-toaster-bottom-left flow-toast",
      });
    },

    manageSubscription() {
      this.$router.push("/settings-subscription");
    },

    unsubscribe() {
      this.$popup.open("UnsubscribePopup", {
        userId: this.user.id,
        callback: this.loadUser,
      });
    },

    userSubscribe() {
      if (this.isAnonymousUser) {
        this.anonymousHandler();
        return;
      }

      this.setCurrentSubscription({
        ...this.user,
        sub: this.user,
        action: () => {
          console.log("callback");
          this.loadUser();
        },
      });
      console.log("post", this.post);
      this.$popup.open("SubscriptionPopup");
    },

    updatePosts(type) {
      this.postsType = type;
      this.reset();
      // this.loadPosts();
    },
    reset() {
      this.isLoading = false;
      this.hasMore = false;
      this.page = 1;
      this.posts = [];
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
      const scrollPosition = window.innerHeight + window.scrollY;
      if (
        document.body.offsetHeight &&
        scrollPosition &&
        document.body.offsetHeight - scrollPosition <= 1000 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    loadUser() {
      return this.waitRequest(() => {
        return this.$get(
          "/users/" + this.username,
          (data) => {
            this.user = new User(data);
            this.settFollowedUser(this.user); // from follow mixin
            // this.loadPosts();
          },
          console.log,
          this.checkErrors
        );
      });
    },
    loadUserCallback() {
      console.log('us calback')
      this.$get(
        "/users/" + this.username,
        (data) => {
          this.user = new User(data);
          this.settFollowedUser(this.user); // from follow mixin
          // this.loadPosts();
        },
        console.log,
        this.checkErrors
      );
    },

    // loadPosts() {
    //   this.isLoading = true;
    //   this.$get(
    //     "/posts/user/" +
    //       this.user.id +
    //       "?page=" +
    //       this.page +
    //       "&type=" +
    //       this.type,
    //       // this.postsType,
    //     (data) => {
    //       let posts = [...this.posts];
    //       for (let obj of data.data) {
    //         posts.push(new Post(obj));
    //       }
    //       this.posts = posts;
    //       this.hasMore = data.next_page_url != null;
    //       this.isLoading = true;
    //     },
    //     (errors) => {
    //       console.log(errors);
    //     }
    //   );
    // },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        // this.loadPosts();
      }
    },
    copyLink() {
      const link = `${process.env.VUE_APP_APP_URL}${this.user.url}`;
      this.$copyText(link);
      this.$bvToast.toast(this.$t("general.link-copied"), {
        autoHideDelay: 5000,
        title: this.$t("general.link"),
        solid: true,
        toaster: "b-toaster-bottom-left flow-toast",
      });
    },
    addToList() {
      if (this.isAnonymousUser) {
        this.anonymousHandler();
        return;
      }

      this.$popup.open("AddToCollectionsPopup", { addedUserId: this.user.id });
    },
    subscribe(bundle) {
      if (this.user.isFree) {
        this.$post(
          "/subscribe/" + this.user.id,
          {},
          () => {
            this.reset();
            this.loadUser();
          },
          (errors) => {
            console.log(errors);
          }
        );
      } else {
        this.$buyItem({
          type: Payment.TYPE_SUBSCRIPTION_NEW,
          user: this.user,
          bundle: bundle,
        });
      }
    },
    tip() {
      this.$buyItem({
        type: Payment.TYPE_TIP,
        user: this.user,
      });
    },
    loadCollections() {
      this.waitRequest(() => {
        return this.$get(
          "/lists/user/" + this.user?.id,
          (data) => {
            this.setContains({ contains: data.contains });
          },
          (errors) => {
            console.log(errors);
          }
        );
      }).catch(this.checkErrors);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/vendors/_variables.scss";
.nav-tabs .nav-link {
  border: none;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  color: $secondary;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: $primary;
  border-color: $primary;
}
</style>

<style lang="scss" scoped>
.profile {
  .page-header {
    top: -56px;
    margin-bottom: -56px;
    transition: top 0.2s;
    color: white;

    .btn,
    a:not(.dropdown-item) {
      padding: 0;
      color: white;
    }
    &.scrolled {
      top: 0;
      margin-bottom: 0;
      color: black;
      .btn,
      a {
        color: black;
      }
    }
  }

  .cover {
    height: em(120);

    &:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      content: "";
      z-index: 3;
    }

    img {
      -o-object-fit: cover;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.profile-avatar {
  position: relative;
  border-radius: 50%;
  margin-left: em(17);
  border: em(3) solid $white;
  margin-top: em(-43);
  z-index: 3;
}

.profile-menu {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: em(9) em(6) em(12);

  &__button {
    color: $black;
    text-decoration: none;
    justify-content: flex-start;

    &:not(:first-child) {
      margin-top: em(15);
    }

    &:active {
      transform: none;
      filter: none;
    }
  }

  &__tooltip {
    padding: em(18) em(18) em(20);
  }

  &__icon {
    &--dots {
      width: em(24, 16);
      height: em(24, 16);
    }

    &--arrow {
      color: $white;
      width: em(24, 16);
      height: em(24, 16);
    }
  }

  &__font {
    color: $black;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 500;

    &--tooltip {
    }
  }
}

.show-more {
  display: inline;
  width: auto;
  align-items: center;
  justify-content: flex-start;

  &__font {
    color: $app-blue;
    font-family: $font-default;
    font-size: em(16);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &:active {
    transform: none;
    filter: none;
  }

  &__arrow {
    width: em(6);
    height: em(4);
    transition: transform $time-normal $ease;
  }
  .not-collapsed &__arrow {
    transform: rotate(-90deg);
  }
}

.profile-location {
  color: #cecece;

  &__font {
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__icon {
    width: em(9);
    height: em(11);
  }
}

.profile-navigation {
  &__button {
  }

  &__icon {
    width: em(27, 24);
    height: em(27, 24);

    &--active {
      ::v-deep .svg-star-to-collection {
        fill: gold;
      }
    }
  }
}

.profile-button {
  $parent: &;
  border-radius: em(100, 24);
  border: 1px solid $app-blue;
  padding: em(7, 24) em(15, 24);

  &__font {
    color: $app-blue;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 24);
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &--white {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;

      color: $white;
    }
  }

  &--edit-profile {
    border: 1px solid $app-purple;
    background-color: $app-purple;
    white-space: nowrap;

    #{$parent} {
      &__font {
        color: $white;
        font-weight: 700;
      }
    }
  }
}

.subscribed-button {
  display: flex;
  justify-content: space-between;
  padding: em(14) em(16);
  border: 1.5px solid $app-gray-25;
  border-radius: 100px;

  &__font {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #308dff;
    text-transform: uppercase;

    &--white {
      color: $white;
    }
  }
}

.profile-discount {
  $parent: &;
  display: flex;
  align-items: center;

  &__buttons {
    margin-top: 12px;
  }

  &__button {
    &:not(:first-of-type) {
      margin-top: 11px;
    }

    &--arrow {
      margin-left: auto;
      margin-top: 0;

      &--open {
        #{$parent}__icon--arrow-to-top {
          transform: rotate(360deg);
          transition: $ease-out-cubic;
        }
      }
    }
  }

  &__font {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 13px;
    color: $app-black;
    flex: 1;
    white-space: nowrap;
  }

  &__icon {
    &--discount {
      width: 17px;
      height: 17px;
      min-width: 17px;
      min-height: 17px;
      margin-right: 6px;
    }

    &--arrow-to-top {
      transform: rotate(180deg);
      margin-left: auto;
      width: 15px;
      height: 15px;
    }
  }

  &--open {
  }
}
</style>
