<template>
  <div class="view-type-row">
    <div class="view-type-row__tabs">
      <CRadio
        v-model="selectedCategory"
        v-for="(item, index) in categories"
        :key="`${index + '-category'}`"
        :name="item.name"
        :value="item"
        group-name="filter-tab"
        class="filter-tab"
      >
        <template #default="{ isActive, value: tab }">
          <div
            class="filter-tab__item"
            :class="{ 'filter-tab__item--active': isActive }"
          >
            <span class="filter-tab__font"> {{ tab.name }}&nbsp;</span>
            <span class="filter-tab__font"> {{ tab.item_count }} </span>
          </div>
        </template>
      </CRadio>
    </div>

    <div class="view-type-row__type">
      <transition name="fade" mode="out-in">
        <button
          type="button"
          @click="toggleActiveView"
          :key="currentViewType.id"
          class="view-button"
        >
          <CIcon
            :name="currentViewType.icon"
            path="profile"
            class="view-button__icon"
          />

          <span class="view-button__font">
            {{ currentViewType.text }}
          </span>
        </button>
      </transition>
    </div>
  </div>
</template>
<script>
import CIcon from "@/features/ui/CIcon.vue";
import CRadio from "@/features/ui/CRadio.vue";

import { viewType } from "@/config/viewPostType";


export default {
  name: "PostSoloGridPanel",
  components: {
    CIcon,
    CRadio,
  },
  props: {
    categoryList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      category: {},
      isGridView: false,
      activeView: viewType.solo,
    };
  },
  computed: {
    currentViewType() {
      return this.viewTypeConfig[this.activeView];
    },

    viewTypeConfig() {
      return {
        [viewType.solo]: {
          id: 1,
          icon: "solo",
          text: this.$t("general.solo"),
          renderComponentName: "AppPost",
        },

        [viewType.grid]: {
          id: 2,
          icon: "grid",
          text: this.$t('general.grid'),
          renderComponentName: "PostTile",
        }
      };
    },

    selectedCategory: {
      get() {
        return this.category;
      },

      set(category) {
        this.category = category;
        this.$emit("change", category);
      },
    },

    categories() {
      return this.categoryList;
    },
  },
  mounted() {
    this.initState();
  },
  methods: {
    initState() {
      this.category = this.categoryList?.[0] // set 'All' category
    },

    toggleActiveView() {
      this.isGridView = !this.isGridView;
      this.activeView = this.isGridView ? viewType.grid : viewType.solo;
      this.$emit("change-view", this.isGridView);
    },
  },
};
</script>

<style lang="scss" scoped>
.view-type-row {
  display: flex;
  flex-wrap: nowrap;
  //padding: em(15) em(12) em(19);

  &__tabs {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    width: 80%;

    @include hideScroll;
    padding: em(12);
  }

  &__type {
    width: 20%;
    display: flex;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 1px;
      height: em(13);
      border-left: 1px solid $app-border-gray-1;
    }
  }
}

.filter-tab {
  max-height: em(19);

  &:not(:first-child) {
    margin-left: em(5);
  }

  &__item {
    display: inline-flex;
    padding: em(4) em(8);
    background-color: $app-border-gray-1;
    border-radius: em(100);
    white-space: nowrap;
  }

  &__font {
    color: $app-gray-19;
    font-family: $font-default;
    font-size: em(9);
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:not(:first-child) {
      &:before {
        content: "  · ";
        color: $app-gray-19;
        font-size: em(16);
        position: relative;
      }
    }
  }
}

.view-button {
  display: inline-flex;
  align-items: center;

  &__icon {
    width: em(15);
    height: em(15);
    margin-right: em(6);
  }

  &__font {
    color: $app-black;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
</style>
