<template>
  <div class="post-list">
    <div class="post-list__tabs">
      <CRadio
        v-model="selectedTab"
        v-for="(item, index) in tabs"
        :key="`${index + '-tab'}`"
        :name="item.name"
        :value="item"
        group-name="user-tab"
        class="user-tab"
      >
        <template #default="{ isActive, value: tab }">
          <div
            class="user-tab__item"
            :class="{ 'user-tab__item--active': isActive }"
          >
            <span class="user-tab__font"> {{ tab.content }} </span>
          </div>
        </template>
      </CRadio>
    </div>

    <template v-if="isFlowActive">
      <div class="post-list__posts">
        <InfinityScroll
          v-if="activePosts && activePosts.length"
          :items="activePosts"
          :has-more="hasMorePosts"
          @rich-end="loadMore"
          is-grid
        >
          <template #default="{ item: post }">
            <PostTile :post="post" is-flow-tile />
          </template>
        </InfinityScroll>
      </div>
    </template>

    <template v-else-if="isMediaActive">
      <div class="post-list__posts">
        <InfinityScroll
          v-if="postsMedia && postsMedia.length"
          :items="postsMedia"
          :has-more="hasMoreMedia"
          @rich-end="loadMoreMedia"
          is-grid
        >
          <template #default="{ item: media }">
            <PostMediaTile :media="media" is-flow-tile />
          </template>
        </InfinityScroll>
      </div>
    </template>

    <template v-else>
      <div class="post-list__view">
        <PostSoloGridPanel
          :category-list="postsCategories"
          @change="setCategory"
          @change-view="changeView"
        />
      </div>

      <div class="post-list__posts" :key="currentPostView">
        <InfinityScroll
          v-if="filteredPostsByCategory && filteredPostsByCategory.length"
          :items="filteredPostsByCategory"
          :has-more="hasMorePosts"
          @rich-end="loadMore"
          :is-grid="isGridView"
        >
          <template #default="{ item: post }">
            <component :is="currentPostView" :post="post" />
          </template>
        </InfinityScroll>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import InfinityScroll from "@/features/components/InfinityScroll.vue";
import CRadio from "@/features/ui/CRadio.vue";
import CIcon from "@/features/ui/CIcon.vue";
import PostTile from "@/features/containers/profile/PostTile.vue";
import PostMediaTile from "@/features/containers/profile/PostMediaTile.vue";
import PostSoloGridPanel from "@/features/containers/profile/PostSoloGridPanel.vue";
import AppPost from "@/features/containers/post/AppPost.vue";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import media from "@/mixins/media";

import { postType } from "@/config/postsType";
import { viewType } from "@/config/viewPostType";

import Post from "@/components/models/Post";

import { nFormat } from "@/tools/helpers";
import userPosts from "@/mixins/userPosts";

const renderedComponents = {
  PostTile: "PostTile",
  AppPost: "AppPost",
};

const MEDIA_TYPE = "media";

export default {
  name: "PostList",
  components: {
    PostSoloGridPanel,
    PostTile,
    PostMediaTile,
    CIcon,
    CRadio,
    InfinityScroll,
    AppPost,
  },
  mixins: [waitRequest, validationError, media, userPosts],
  props: {
    userId: {
      type: Number,
      required: true,
    },

    stat: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      category: {},
      tab: {
        id: 1,
        postListType: postType.flows,
        renderComponentName: renderedComponents.PostTile,
        content: `${this.flowsAmount} ${this.$t("general.profile.tabs.flow")}`,
      },

      isGridView: false,
    };
  },

  computed: {
    ...mapGetters({
      posts: "posts/posts",
      postsMeta: "posts/postsMeta",
    }),

    postsCategories() {
      return this.stat?.postsTags || [];
    },

    viewTypeConfig() {
      return {
        [viewType.solo]: {
          id: 1,
          icon: "solo",
          text: this.$t("general.solo"),
          renderComponentName: "AppPost",
        },

        [viewType.grid]: {
          id: 2,
          icon: "grid",
          text: this.$t('general.grid'),
          renderComponentName: "PostTile",
        }
      };
    },

    currentPostView() {
      if (this.tab.postListType === postType.flows) return "PostTile";

      return this.currentViewType.renderComponentName;
    },

    filteredPostsByTab() {
      return {
        [postType.flows]: this.posts.flows,
        [postType.posts]: this.posts.posts,
        media: [...this.posts.flows, ...this.posts.posts],
      };
    },

    filteredPostsByCategory() {
      return (
        this.filteredPostsByTab?.posts
          .map((item) => {
            // add base hashtags caegory
            const categoryForAll = this.postsCategories?.[0];
            const baseHastag = { ...categoryForAll, id: 0 };

            if (item.hashtags?.length) {
              item.hashtags = [baseHastag, ...item.hashtags];
            } else {
              item.hashtags = [baseHastag];
            }

            return item;
          })
          // filter post by selected category
          .filter((post) => {
            return post?.hashtags.some((item) => item?.id === this.category.id);
          })
          .map((post) => new Post(post))
      );
    },

    activePosts() {
      return this.filteredPostsByTab[this.tab.postListType].map(
        (post) => new Post(post)
      );
    },

    hasMorePosts() {
      return Boolean(this.postsMeta?.[this.tab.postListType]?.next_page_url);
    },

    page() {
      return this.postsMeta?.[this.tab.postListType]?.current_page || 1;
    },

    flowsAmount() {
      return nFormat(this.stat?.flows || 0);
    },
    postsAmount() {
      return nFormat(this.stat?.posts || 0);
    },
    mediaAmount() {
      return nFormat(this.stat?.media || 0);
    },

    currentViewType() {
      return this.viewTypeConfig[this.activeView];
    },

    isFlowActive() {
      return this.tab.postListType === postType.flows;
    },

    isMediaActive() {
      return this.tab.postListType === MEDIA_TYPE;
    },

    selectedTab: {
      get() {
        return this.tab;
      },

      set(tab) {
        this.tab = tab;
      },
    },

    tabs() {
      return [
        {
          id: 1,
          postListType: postType.flows,
          renderComponentName: renderedComponents.PostTile,
          content: `${this.flowsAmount} ${this.$t(
            "general.profile.tabs.flow"
          )}`,
        },
        {
          id: 2,
          postListType: postType.posts,
          renderComponentName: renderedComponents.AppPost,
          content: `${this.postsAmount} ${this.$t(
            "general.profile.tabs.posts"
          )}`,
        },
        {
          id: 3,
          postListType: MEDIA_TYPE,
          renderComponentName: renderedComponents.AppPost,
          content: `${this.mediaAmount} ${this.$t(
            "general.profile.tabs.media"
          )}`,
        },
      ];
    },
  },

  mounted() {
    this.setUserMediaId(this.userId); // from media mixin
    this.initState();
  },

  methods: {
    initState() {
      this.initialLoadPost().then(() => {
        this.initialLoadMedia(); // from media mixin
      });

      this.category = this.postsCategories?.[0];
    },

    setCategory(category) {
      this.category = category;
    },

    changeView(value) {
      this.isGridView = value;
      this.activeView = this.isGridView ? viewType.grid : viewType.solo;
    },

    setCurrentPost(data) {
      this.setPosts({ posts: [data], type: this.selectedTab.postListType });
    },
  },
};
</script>

<style lang="scss" scoped>
.post-list {
  margin-right: -15px;
  margin-left: -15px;

  &__tabs {
    display: flex;
    flex-wrap: nowrap;
  }

  &__posts {
    margin-top: 1px;
  }
}

.user-tab {
  flex: 1;
  text-align: center;

  &__item {
    padding-top: em(15);
    padding-bottom: em(17);
    border-bottom: 1px solid $app-gray-7;

    &--active {
      padding-bottom: em(16);
      border-bottom: 2px solid $app-blue;
    }
  }

  &__font {
    color: $app-black;
    text-align: center;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
</style>
