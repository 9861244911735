<template>
  <CLink
    :to="postUrl"
    class="post-media-tile"
    :class="{ 'post-media-tile--disabled': !hasAccess }"
    :style="backgroundStyle"
  >
    <button
      type="button"
      class="post-media-tile__subscription"
      v-if="!hasAccess"
    >
      <span class="post-media-tile__icon-wrapper">
        <CIcon
          name="lock"
          path="flows"
          class="post-media-tile__icon post-media-tile__icon--lock"
        />
      </span>

      <span class="post-media-tile__font"> {{ accessTypeMessage }} </span>
    </button>

    <template v-if="isFlow">
      <div class="post-media-tile__view">
        <span class="post-media-tile__icon-wrapper">
          <CIcon
            name="video"
            path="flows"
            class="post-media-tile__icon post-media-tile__icon--view"
          />
        </span>

        <span class="post-media-tile__font">
          {{ viewCount }}
        </span>
      </div>
    </template>

    <template v-else>
      <div
        v-for="icon in descriptionIcons"
        :key="icon.id"
        class="post-media-tile__description"
        :class="{
          [`post-media-tile__description--${icon.position}`]: icon.position,
        }"
      >
        <CIcon
          :name="icon.name"
          path="flows"
          class="post-media-tile__icon post-media-tile__icon--view"
          :class="{ [`post-media-tile__icon--${icon.name}`]: icon.name }"
        />
      </div>
    </template>
  </CLink>
</template>

<script>
import Post from "@/components/models/Post";
import { nFormat } from "@/tools/helpers";
import CIcon from "@/features/ui/CIcon.vue";
import CLink from "@/features/ui/CLink.vue";
import { mapGetters } from "vuex";

export default {
  name: "PostMediaTile",
  components: { CLink, CIcon },
  props: {
    media: {
      type: Object,
      required: true,
    },

    isDisabledLink: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      user: "user",
    }),

    accessTypeMessage() {
      const accessType = parseInt(this.currentPost?.access_type);
      switch (accessType) {
        case Post.ACCESS_TYPE_FOLLOWERS:
          return this.$t("general.accessType.followers");
        case Post.ACCESS_TYPE_SUBSCRIBERS:
          return this.$t("general.accessType.subscribers");
        case Post.ACCESS_TYPE_PAID_POST:
          return this.$tc(
            "general.accessType.paid",
            this.currentPost.face_price || 0,
            {
              price: this.currentPost.face_price || 0,
            }
          );
        default:
          return "";
      }
    },

    isOwner() {
      const isPostOwner = this.media?.posts?.user?.id === this.user.id;

      return this.isAdmin || isPostOwner;
    },

    currentPost() {
      return new Post(this.media?.posts?.[0]);
    },

    postUrl() {
      if (!this.hasAccess) return "";
      if (this.isFlow) {
        return `/flow/${this.currentPost.id}`;
      }

      return this.currentPost.url;
    },

    imageUrl() {
      if (this.media?.screenshot?.url) {
        const { url = "", jwt = "" } = this.media?.screenshot;
        return `${url}?token=${jwt}`;
      }

      if (this.media?.thumbs?.length) {
        const { url = "", jwt = "" } = this.media?.thumbs?.[0]?.url;
        return `${url}?token=${jwt}`;
      }

      const mux = this.media?.url?.url;
      return mux || "/mock/header.webp";
    },

    hasAccess() {
      return this.currentPost.hasAccess;
    },

    isFlow() {
      return this.currentPost.isFlow;
    },

    isVideo() {
      return !this.isFlow && this.currentPost.hasVideo;
    },

    isSlides() {
      return !this.isFlow && this.currentPost.hasSlides;
    },

    backgroundStyle() {
      return {
        // "background-image": `url(${this.imageUrl})`,
        "--image-path": `url(${this.imageUrl})`,
      };
    },

    viewCount() {
      return nFormat(this.currentPost?.views || 0);
    },

    icons() {
      return [
        { id: 1, name: "flow", position: "top-right", isShow: this.isFlow },
        { id: 2, name: "video", position: "top-right", isShow: this.isVideo },
        {
          id: 3,
          name: "slides",
          position: "bottom-right",
          isShow: this.isSlides,
        },
      ];
    },

    descriptionIcons() {
      return this.icons.filter((icon) => icon.isShow);
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.post-media-tile {
  --image-path: null;

  position: relative;
  z-index: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-color: $app-gray;
  background-image: var(--image-path);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &__view,
  &__edit,
  &__subscription {
    display: flex;
    align-items: center;
    padding: em(3);
    border-radius: em(3);
    background-color: rgba($black, 0.7);
  }

  &__view {
    position: absolute;
    left: em(7);
    top: em(7);
  }

  &__subscription {
    width: auto;
    position: absolute;
    left: em(7);
    bottom: em(7);
  }

  &__edit {
    width: auto;
    position: absolute;
    right: em(7);
    top: em(7);
  }

  &__description {
    position: absolute;
    display: inline-flex;

    &--top-right {
      right: em(7);
      top: em(7);
    }

    &--bottom-right {
      right: em(7);
      bottom: em(7);
    }
  }

  &__font {
    color: $white;
    font-family: $font-default;
    font-size: em(10);
    font-style: normal;
    font-weight: 500;
    display: inline-flex;
    align-items: flex-end;
  }

  &__icon-wrapper {
    width: em(13);
    height: em(13);
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    color: $white;

    &--view {
      margin-right: 1px;
      width: em(9);
      height: em(9);
    }

    &--lock {
      margin-right: em(5);
      width: em(9);
      height: em(9);
    }

    &--flow,
    &--video,
    &--slides {
      width: em(13);
      height: em(13);
    }
  }

  &--disabled {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(10px);
    }
  }
}
</style>
