<template>
  <div class="promotion-card">
    <div class="promotion-card__title">
      <span class="promotion-card__font promotion-card__font--title">
        {{ promoTile }}
      </span>
    </div>

    <div class="promotion-card__content">
      <div class="promotion-body" v-if="this.card.promo.message">
        <div class="promotion-body__image-wrapper">
          <img
            v-if="card.avatar"
            :src="card.avatar"
            alt=""
            class="promotion-body__image"
          />
        </div>

        <div class="promotion-body__text">
          <span class="promotion-body__font">
            {{ this.card.promo.message }}
          </span>
        </div>
      </div>
    </div>

    <div class="promotion-card__time">
      <span
        class="promotion-card__font promotion-card__font--time promotion-card__font--strong"
      >
        {{ $t("general.promo.timeLeft", { left: card.promo.left }) }}
      </span>
      <span class="promotion-card__font promotion-card__font--time">
        {{ $t("general.promo.timeEnd", { end: date.end }) }}
      </span>
    </div>
  </div>
</template>

<script>
import {formatDateTime} from "@/tools/helpers";

export default {
  name: "PromotionCard",
  props: {
    card: {
      type: Object,
      required: true,
    },
  },

  computed: {
    promoTile() {
      return   this.$t("general.promo.promoCardTitle", {
        type: this.card.promo.type === 2 ?  'First Month' : "Free Trial",
        limit:  this.card.promo.type === 2 ?  `${ this.card.promo.discount_percent || 0}% Off` : `${this.card.promo.trial_duration_days || 0} Days`,
      })
    },
    date() {
      return {
        end: this.formatDateTime(this.card.promo.end_at, {
          month: "short",
          day: "2-digit",
        }).date,
        start: this.formatDateTime(this.card.promo.start_at, {
          month: "short",
          day: "2-digit",
        }).date,
      };
    },
  },
  methods: {
    formatDateTime,
  }
};
</script>

<style lang="scss" scoped>
.promotion-card {
  margin-top: 8px;
  &__title {
  }

  &__font {
    &--title {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 14px;
      color: $app-gray-26;
    }

    &--time {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 10px;
      color: $app-gray-28;
    }

    &--strong {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 10px;
      color: $app-gray-28;
    }
  }

  &__content {
    margin-top: 8px;
    display: flex;
  }

  &__time {
    margin-top: 11px;
  }
}

.promotion-body {
  display: flex;
  align-items: center;
  border-radius: 100px;
  width: auto;
  height: 28px;
  padding: 3px;
  background-color: $app-gray-25;

  &__image-wrapper {
    margin-right: 6px;
  }

  &__image {
    border-radius: 100px;
    width: 22px;
    height: 22px;
  }

  &__text {
    margin-right: 6px;
  }

  &__font {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 12px;
    color: $black;
  }
}


</style>
